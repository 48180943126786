import { Fragment, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import SWBreadcrumb from '@/components/Global/SWBreadcrumb';
import SWErrorModal from '@/components/Global/SWErrorModal';
import SWLoginModal from '@/components/Global/SWLoginModal';
import SWMsDomainModal from '@/components/Global/SWMsDomainModal/SWMsDomainModal';
import SWFilterFlyout from '@/components/pageBuilder/Elements/SWFilterFlyout';
import SWPortfolioCard from '@/components/pageBuilder/Elements/SWPortfolioCard';
import SWPortfolioDisplayHeader from '@/components/pageBuilder/Elements/SWPortfolioDisplayHeader';
import SWProductIntro from '@/components/pageBuilder/Elements/SWProductIntro/SWProductIntro';
import SWSectionSearchBar from '@/components/pageBuilder/Elements/SWSectionSearchBar';
import SWSectionSelect from '@/components/pageBuilder/Elements/SWSectionSelect';
// Source Web Page Components Imports
import SWAccordionCardCarousel from '@/components/pageBuilder/Sections/SWAccordionCardCarousel';
import SWBasket from '@/components/pageBuilder/Sections/SWBasket';
import SWBillingAccounts, {
  CONTENTFUL_SECTION_SW_BILLING_ACCOUNTS,
} from '@/components/pageBuilder/Sections/SWBillingAccounts';
import SWBusinessAppsBanner from '@/components/pageBuilder/Sections/SWBusinessAppsBanner';
import SWCarouselIcons from '@/components/pageBuilder/Sections/SWCarouselIcons';
import SWConfigureBundle from '@/components/pageBuilder/Sections/SWConfigureBundle';
import SWConfigureBundleOptions from '@/components/pageBuilder/Sections/SWConfigureBundleOptions';
import SWConfigureBusinessNeeds from '@/components/pageBuilder/Sections/SWConfigureBusinessNeeds';
import SWContentBlockWithVideo from '@/components/pageBuilder/Sections/SWContentBlockWithVideo';
import SWCookieDetect from '@/components/pageBuilder/Sections/SWCookieDetect';
import { CONTENTFUL_SECTION_SW_COOKIE_CONSENT } from '@/components/pageBuilder/Sections/SWCookieDetect/SWCookieDetect';
import SWHeroBannerWithSubHeader from '@/components/pageBuilder/Sections/SWHeroBannerWithSubHeader';
import SWNavigationalTabs from '@/components/pageBuilder/Sections/SWNavigationalTabs';
import SWPortfolio from '@/components/pageBuilder/Sections/SWPortfolio';
import SWPricingContent, {
  CONTENTFUL_SECTION_SW_PRICING_CONTENT,
} from '@/components/pageBuilder/Sections/SWPricingContent';
import SWProductDescriptionAndBuy from '@/components/pageBuilder/Sections/SWProductDescriptionAndBuy';
import SWRelatedProducts from '@/components/pageBuilder/Sections/SWRelatedProducts';
import SWSectionFAQs from '@/components/pageBuilder/Sections/SWSectionFAQs/SWSectionFAQs';
import SWSectionFeatureCard from '@/components/pageBuilder/Sections/SWSectionFeatureCard';
import SWSectionFiftyFiftyBanner from '@/components/pageBuilder/Sections/SWSectionFiftyFiftyBanner';
import SWSectionHeroBannerUpload from '@/components/pageBuilder/Sections/SWSectionHeroBannerUpload';
import SWSectionSelectAndSearchBar from '@/components/pageBuilder/Sections/SWSectionSelectAndSearchBar';
import SWStandardBanner from '@/components/pageBuilder/Sections/SWStandardBanner';
import SWSectionQuickLinks from '@/components/pageBuilder/Sections/SWSectionQuickLinks';
import SWSectionTariffCards from '@/components/pageBuilder/Sections/SWSectionTariffCards';
import { ContentSectionProps } from '@/types/Contentful.types';

import { PageBuilderProps } from './page-builder';
import SWShortBanner from '@/components/pageBuilder/Sections/SWShortBanner';
import SWGuidedSellingBanner from '@/components/pageBuilder/Sections/SWGuidedSellingBanner/SWGuidedSellingBanner';

import SWForm from '@/components/pageBuilder/Sections/SWForm';
import SWFormModal from '@/components/pageBuilder/Sections/SWFormModal';
import { SWComparisonTable } from '@/components/pageBuilder/Sections/SWComparisonTable';
import SWSectionServicesBanner from '@/components/pageBuilder/Sections/SWSectionServicesBanner';
import SWSectionTabs from '@/components/pageBuilder/Sections/SWSectionTabs';
import SectionBasketSummary from '@/components/pageBuilder/Sections/SectionBasketSummary';
import RegisterAccount from '@/components/Global/RegisterAccount/RegisterAccount';

import { CreditVettingForm } from '@/components/pageBuilder/Sections/CreditVettingForm';
import OrderCompletedPurchase from '@/components/pageBuilder/Sections/OrderCompletedPurchase';
import SectionTwoFactorAuth from '@/components/pageBuilder/Sections/SectionTwoFactorAuth';
import AdditionalLinks from '@/components/pageBuilder/Sections/AdditionalLinks';

import SWSectionStandardBanner from '@/components/pageBuilder/Sections/SWSectionStandardBanner';
import SWBannerCarousel from '@/components/pageBuilder/Sections/SWBannerCarousel';

export const CONTENTFUL_SECTION_SW_HERO_BANNER_WITH_SUB_HEADER = 'ContentfulSectionSwHeroBannerWithSubHeader';
export const CONTENTFUL_SECTION_SW_SHORT_BANNER = 'ContentfulSectionSwShortBanner';
export const CONTENTFUL_SECTION_SW_GUIDED_SELLING_BANNER = 'ContentfulSectionSwGuidedSellingBanner';

const CONTENTFUL_SECTION_SW_CAROUSEL_ICONS = 'ContentfulSectionSwCarouselIcons';
const CONTENTFUL_SECTION_SW_FORM = 'ContentfulSectionSwForm';
export const CONTENTFUL_SECTION_SW_FORM_MODAL = 'ContentfulSectionSwFormModal';
export const CONTENTFUL_NEW_ACCOUNT_REGISTRATION_FLOW = 'ContentfulNewAccountRegistrationFlow';
export const CONTENTFUL_SECTION_CREDIT_VETTING_FORM = 'ContentfulSectionCreditVettingForm';

const CONTENTFUL_SECTION_PORTFOLIO_CARD = 'ContentfulSectionSwPortfolioCard';
export const CONTENTFUL_SECTION_SW_ACCORDION_CARD_CAROUSEL = 'ContentfulSectionSwAccordionCardCarousel';
const CONTENTFUL_SECTION_SW_BASKET = 'ContentfulSectionSwBasket';
export const CONTENTFUL_SECTION_SW_BREADCRUMB = 'ContentfulSectionSwBreadcrumb';
const CONTENTFUL_SECTION_SW_BUSINESS_APPS_BANNER = 'ContentfulSectionSwBusinessAppsBanner';
export const CONTENTFUL_SECTION_SW_CONTENT_BLOCK_WITH_VIDEO = 'ContentfulSectionSwContentBlockWithVideo';
export const CONTENTFUL_SECTION_SW_CONTENT_BLOCK_WITH_VIDEO_GROUP = 'ContentfulSectionSwContentBlockWithVideoGroup';
const CONTENTFUL_SECTION_SW_FEATURE_CARD = 'ContentfulSectionSwFeatureCard';
export const CONTENTFUL_SECTION_SW_OVERVIEW_FEATURE = 'ContentfulSectionSwFeatureOverview';
const CONTENTFUL_SECTION_SW_FEATURED_PRODUCTS_WITH_SW_FEATURE_CARD =
  'ContentfulSectionSwFeaturedProductsWithSwFeatureCard';
export const CONTENTFUL_SECTION_SW_FIFTY_FIFTY_BANNER = 'ContentfulSectionSwFiftyFiftyBanner';
const CONTENTFUL_SECTION_SW_FILTER_FLYOUT = 'ContentfulSectionSwFilterFlyout';
const CONTENTFUL_SECTION_SW_STANDARD_BANNER = 'ContentfulSectionSwFullHeightBanner';
const CONTENTFUL_SECTION_SW_NAVIGATION_TABS = 'ContentfulSectionSwNavigationalTabs';
const CONTENTFUL_SECTION_SW_PORTFOLIO = 'ContentfulSectionSwPortfolio';
const CONTENTFUL_SECTION_SW_PORTFOLIO_DISPLAY_HEADER = 'ContentfulSectionSwPortfolioDisplayHeader';
const CONTENTFUL_SECTION_SW_PRODUCT_DESCRIPTION_AND_BUY = 'ContentfulSectionSwProductDescriptionAndBuy';
export const CONTENTFUL_SECTION_SW_PRODUCT_LIST = 'ContentfulSectionSwProductList';
export const CONTENTFUL_SECTION_SW_RELATED_ARTICLES = 'ContentfulSectionSwRelatedArticles';
export const CONTENTFUL_SECTION_SW_RELATED_PRODUCTS = 'ContentfulSectionSwRelatedProducts';
const CONTENTFUL_SECTION_SW_SEARCH_INPUT = 'ContentfulSectionSwSearchInput';
const CONTENTFUL_SECTION_SW_SELECT = 'ContentfulSectionSwSelect';
const CONTENTFUL_SECTION_SW_HERO_BANNER_UPLOAD = 'ContentfulSectionSwHeroBannerUpload';
export const CONTENTFUL_SECTION_SW_ERROR_MODAL = 'ContentfulSectionSwErrorModal';
export const CONTENTFUL_SECTION_SW_LOGIN_MODAL = 'ContentfulSectionSwLoginModal';
export const CONTENTFUL_SECTION_SW_QUICK_LINKS = 'ContentfulSectionSwQuickLinks';
export const CONTENTFUL_SECTION_SW_TARIFF_CARDS = 'ContentfulSectionSwTariffCards';
export const CONTENTFUL_SECTION_SW_SELECT_AND_SEARCH_BAR = 'ContentfulSectionSwSelectAndSearchBar';
export const CONTENTFUL_SECTION_SW_CONFIGURE_BUNDLE = 'ContentfulSectionSwConfigureBundle';
export const CONTENTFUL_SECTION_SW_CONFIGURE_BUNDLE_OPTIONS = 'ContentfulSectionSwConfigureBundleOptions';
export const CONTENTFUL_SECTION_SW_CONFIGURE_BUSINESS_NEEDS = 'ContentfulSectionSwConfigureBusinessNeeds';
export const CONTENTFUL_SECTION_SW_PRODUCT_INTRO = 'ContentfulSectionSwProductIntro';
export const CONTENTFUL_SECTION_SW_MS_DOMAIN_MODAL = 'ContentfulSectionSwMsDomainModal';
export const CONTENTFUL_SECTION_SW_FAQS = 'ContentfulSectionSwfaQs';
export const CONTENTFUL_SECTION_SW_COMPARISON_TABLE = 'ContentfulSectionSwComparisonTable';
export const CONTENTFUL_SECTION_SW_SERVICES_BANNER = 'ContentfulSectionSwServicesBanner';
export const CONTENTFUL_SECTION_SW_TABS = 'ContentfulSectionSwTabs';
export const CONTENTFUL_SECTION_SW_BASKET_SUMMARY = 'ContentfulSectionSwBasketSummary';
export const CONTENTFUL_SECTION_ORDER_FLOW_COMPLETED_PURCHASE = 'ContentfulSectionOrderFlowCompletedPurchase';
export const CONTENTFUL_SECTION_TWO_FACTOR_AUTH = 'ContentfulSectionTwoFactorAuthentication';
export const CONTENTFUL_SECTION_ADDITIONAL_LINKS = 'ContentfulSectionAdditionalLinks';
export const CONTENTFUL_SECTION_BANNER_CAROUSEL = 'ContentfulSectionBannerCarousel';
export const CONTENTFUL_SECTION_STANDARD_BANNER = 'ContentfulSectionStandardBanner';

// Find a way to reduce the size of switch cases
const makePageSections = (
  sections: ContentSectionProps[],
  recaptchaToken: PageBuilderProps['recaptchaToken'],
  seoTitle: string
): ReactNode[] => {
  return sections.map((section, index) => {
    switch (section.__typename) {
      case CONTENTFUL_SECTION_SW_HERO_BANNER_WITH_SUB_HEADER:
        return <SWHeroBannerWithSubHeader key={section.id} {...section} />;

      case CONTENTFUL_SECTION_SW_HERO_BANNER_UPLOAD:
        return <SWSectionHeroBannerUpload key={section.id} {...section} />;

      case CONTENTFUL_SECTION_SW_SHORT_BANNER:
        return <SWShortBanner key={section.id} {...section} />;

      case CONTENTFUL_SECTION_SW_TARIFF_CARDS:
        return <SWSectionTariffCards key={section.id} {...section} />;

      case CONTENTFUL_SECTION_SW_GUIDED_SELLING_BANNER:
        return <SWGuidedSellingBanner key={section.id} {...section} />;

      case CONTENTFUL_SECTION_SW_CAROUSEL_ICONS: {
        return <SWCarouselIcons key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_FORM: {
        return <SWForm key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_FORM_MODAL: {
        return <SWFormModal key={section.id} {...section} />;
      }

      case CONTENTFUL_NEW_ACCOUNT_REGISTRATION_FLOW: {
        return <RegisterAccount key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_CREDIT_VETTING_FORM: {
        return <CreditVettingForm key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_PORTFOLIO: {
        return <SWPortfolio key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_PORTFOLIO_DISPLAY_HEADER: {
        return <SWPortfolioDisplayHeader key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_PORTFOLIO_CARD: {
        return <SWPortfolioCard key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_FEATURE_CARD: {
        return <SWSectionFeatureCard key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_FEATURED_PRODUCTS_WITH_SW_FEATURE_CARD: {
        return <SWSectionFeatureCard key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_STANDARD_BANNER: {
        return <SWStandardBanner key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_BREADCRUMB: {
        return <SWBreadcrumb key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_FIFTY_FIFTY_BANNER: {
        return <SWSectionFiftyFiftyBanner key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_SELECT: {
        return <SWSectionSelect key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_SEARCH_INPUT: {
        return <SWSectionSearchBar key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_SELECT_AND_SEARCH_BAR: {
        return <SWSectionSelectAndSearchBar key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_QUICK_LINKS: {
        return <SWSectionQuickLinks key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_PRODUCT_DESCRIPTION_AND_BUY: {
        return (
          <Fragment key={section.id}>
            <SWProductDescriptionAndBuy {...section} />
            <SectionBasketSummary
              key={section.id}
              id={uuidv4()}
              name=""
              __typename="ContentfulSectionSwBasketSummary"
            />
          </Fragment>
        );
      }

      case CONTENTFUL_SECTION_SW_ACCORDION_CARD_CAROUSEL: {
        return <SWAccordionCardCarousel key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_NAVIGATION_TABS: {
        return <SWNavigationalTabs key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_FILTER_FLYOUT: {
        return <SWFilterFlyout key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_BASKET: {
        return (
          <>
            <SWBasket key={section.id} {...section} />
          </>
        );
      }

      case CONTENTFUL_SECTION_SW_BASKET_SUMMARY: {
        return <SectionBasketSummary key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_CONTENT_BLOCK_WITH_VIDEO: {
        return <SWContentBlockWithVideo key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_PRICING_CONTENT: {
        return <SWPricingContent key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_RELATED_PRODUCTS: {
        return <SWRelatedProducts key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_BUSINESS_APPS_BANNER: {
        return <SWBusinessAppsBanner key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_CONFIGURE_BUNDLE: {
        return <SWConfigureBundle key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_LOGIN_MODAL: {
        return <SWLoginModal key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_ERROR_MODAL: {
        return <SWErrorModal key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_CONFIGURE_BUNDLE_OPTIONS: {
        return <SWConfigureBundleOptions key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_CONFIGURE_BUSINESS_NEEDS: {
        return <SWConfigureBusinessNeeds key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_MS_DOMAIN_MODAL: {
        return <SWMsDomainModal key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_BILLING_ACCOUNTS: {
        return <SWBillingAccounts key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_COOKIE_CONSENT: {
        return <SWCookieDetect key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_PRODUCT_INTRO: {
        return (
          <Fragment key={section.id}>
            <SWProductIntro key={section.id} {...section} />
            <SectionBasketSummary
              key={section.id}
              id={uuidv4()}
              name=""
              __typename="ContentfulSectionSwBasketSummary"
            />
          </Fragment>
        );
      }

      case CONTENTFUL_SECTION_SW_FAQS: {
        return <SWSectionFAQs key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_COMPARISON_TABLE: {
        return <SWComparisonTable key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_SERVICES_BANNER: {
        return <SWSectionServicesBanner key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_TABS: {
        return <SWSectionTabs key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_ORDER_FLOW_COMPLETED_PURCHASE: {
        return <OrderCompletedPurchase key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_TWO_FACTOR_AUTH: {
        return <SectionTwoFactorAuth key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_ADDITIONAL_LINKS: {
        return <AdditionalLinks key={section.id} {...section} />;
      }
      case CONTENTFUL_SECTION_BANNER_CAROUSEL: {
        return <SWBannerCarousel key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_STANDARD_BANNER: {
        return <SWSectionStandardBanner key={section.id} {...section} />;
      }
      default:
        return <></>;
    }
  });
};

export default makePageSections;
