import styled from '@emotion/styled';
import { rem } from 'polished';
import { Tabs } from '@legacy-vfuk/core-tabs';

export const TabsContainer = styled.div`
  background-color: var(--white);
  margin: 0 auto;
  width: 100%;
  padding: ${rem('30px')} 0 0 0;
  [class*='Tabsstyle__TabListContainer-sc'] {
    max-width: ${rem('1180px')};
    margin: 0 auto;
    > [class*='Tabsstyle__TabList-sc'] {
      justify-content: flex-start;
      display: flex;
      [class*='FunctionalTabstyle__ClickableTab'][tabindex='0'] > div,
      [class*='FunctionalTabstyle__ClickableTab'][aria-selected='true'] > div {
        border-bottom: ${rem('4px')} solid red;
      }
      ::before {
        background: transparent !important;
      }
    }
  }
`;
export const TabsWrapper = styled(Tabs)``;
